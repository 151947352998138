exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-api-js": () => import("./../../../src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-casestudies-index-js": () => import("./../../../src/pages/casestudies/index.js" /* webpackChunkName: "component---src-pages-casestudies-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-documentation-js": () => import("./../../../src/pages/documentation.js" /* webpackChunkName: "component---src-pages-documentation-js" */),
  "component---src-pages-ebooks-index-js": () => import("./../../../src/pages/ebooks/index.js" /* webpackChunkName: "component---src-pages-ebooks-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-index-js": () => import("./../../../src/pages/industries/index.js" /* webpackChunkName: "component---src-pages-industries-index-js" */),
  "component---src-pages-introduction-index-js": () => import("./../../../src/pages/introduction/index.js" /* webpackChunkName: "component---src-pages-introduction-index-js" */),
  "component---src-pages-iso-index-js": () => import("./../../../src/pages/iso/index.js" /* webpackChunkName: "component---src-pages-iso-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-media-index-js": () => import("./../../../src/pages/media/index.js" /* webpackChunkName: "component---src-pages-media-index-js" */),
  "component---src-pages-newsletter-index-js": () => import("./../../../src/pages/newsletter/index.js" /* webpackChunkName: "component---src-pages-newsletter-index-js" */),
  "component---src-pages-partners-index-js": () => import("./../../../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-press-centre-index-js": () => import("./../../../src/pages/press-centre/index.js" /* webpackChunkName: "component---src-pages-press-centre-index-js" */),
  "component---src-pages-pricing-call-and-sms-js": () => import("./../../../src/pages/pricing/call-and-sms.js" /* webpackChunkName: "component---src-pages-pricing-call-and-sms-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-product-index-js": () => import("./../../../src/pages/product/index.js" /* webpackChunkName: "component---src-pages-product-index-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("./../../../src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */),
  "component---src-pages-whats-new-index-js": () => import("./../../../src/pages/whats-new/index.js" /* webpackChunkName: "component---src-pages-whats-new-index-js" */),
  "component---src-templates-blog-blog-post-js": () => import("./../../../src/templates/blog/blog-post.js" /* webpackChunkName: "component---src-templates-blog-blog-post-js" */),
  "component---src-templates-blog-blog-post-list-js": () => import("./../../../src/templates/blog/blog-post-list.js" /* webpackChunkName: "component---src-templates-blog-blog-post-list-js" */),
  "component---src-templates-blog-legacy-blog-post-js": () => import("./../../../src/templates/blog/legacy-blog-post.js" /* webpackChunkName: "component---src-templates-blog-legacy-blog-post-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-dummy-redirector-js": () => import("./../../../src/templates/dummy-redirector.js" /* webpackChunkName: "component---src-templates-dummy-redirector-js" */),
  "component---src-templates-ebook-file-js": () => import("./../../../src/templates/ebook-file.js" /* webpackChunkName: "component---src-templates-ebook-file-js" */),
  "component---src-templates-ebook-js": () => import("./../../../src/templates/ebook.js" /* webpackChunkName: "component---src-templates-ebook-js" */),
  "component---src-templates-independent-page-js": () => import("./../../../src/templates/independent-page.js" /* webpackChunkName: "component---src-templates-independent-page-js" */),
  "component---src-templates-industry-js": () => import("./../../../src/templates/industry.js" /* webpackChunkName: "component---src-templates-industry-js" */),
  "component---src-templates-integrations-index-js": () => import("./../../../src/templates/integrations/index.js" /* webpackChunkName: "component---src-templates-integrations-index-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-partner-page-js": () => import("./../../../src/templates/partner-page.js" /* webpackChunkName: "component---src-templates-partner-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

